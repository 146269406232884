import { omit } from 'lodash'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { Applicant, ApplicantResponseBody, ApplicantStatus, ApplicantDocuments } from 'interfaces/applicant.interface'

export interface ApplicantState {
  applicant: Applicant
  applicantStatus: RequestStatus
  applicantInitialLoading: boolean
  applicantError: RequestError | undefined
  applicantDocuments: ApplicantDocuments[]
  applicantDocumentsStatus: RequestStatus
  applicantDocumentsError: RequestError | undefined
}

const initialState: ApplicantState = {
  applicant: {
    first_name: '',
    last_name: '',
    has_ssn: true,
    primary_id_document: {
      type: '',
      id_number: '',
      issuing_country: '',
      issuing_state: '',
      expiration_date: null
    },
    secondary_id_document: { type: '' },
    home_address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: 'US'
    },
    phone_number: '',
    status: ApplicantStatus.Unverified,
    flow_version: 'v2'
  },
  applicantStatus: RequestStatus.Pending,
  applicantInitialLoading: true,
  applicantError: undefined,
  applicantDocuments: [],
  applicantDocumentsStatus: RequestStatus.Pending,
  applicantDocumentsError: undefined
}

interface FetchApplicantDocumentsParams {
  accountId: number
}

export const fetchApplicant = createAsyncThunk('applicant/fetchApplicant', async () => {
  try {
    const response: { data: ApplicantResponseBody } = await apiClient.get('mail-authorization/applicant')
    return response.data
  } catch (e: any) {
    const error: AxiosError = { ...e }
    if (error.response?.status === 404) return initialState.applicant
    throw e
  }
})

export const fetchApplicantDocuments = createAsyncThunk(
  'applicant/fetchApplicantDocuments',
  async ({ accountId }: FetchApplicantDocumentsParams) => {
    try {
      const response: { data: ApplicantDocuments[] } = await apiClient.get(
        `mail-authorization/accounts/${accountId}/applicant/documents`
      )
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response?.status === 404) return initialState.applicantDocuments
      throw e
    }
  }
)

export const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.applicantStatus = RequestStatus.Success
        // Omitting the issuing_entity field since it's not used anymore in the UI
        state.applicant = omit(action.payload, 'primary_id_document.issuing_entity')
        state.applicantInitialLoading = false
        state.applicantError = undefined
      })
      .addCase(fetchApplicant.pending, state => {
        state.applicantStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicant.rejected, (state, action) => {
        state.applicantStatus = RequestStatus.Error
        state.applicant = initialState.applicant
        state.applicantError = action.error.message
      })
      .addCase(fetchApplicantDocuments.fulfilled, (state, action) => {
        state.applicantDocuments = action.payload
        state.applicantDocumentsStatus = RequestStatus.Success
        state.applicantDocumentsError = undefined
      })
      .addCase(fetchApplicantDocuments.rejected, (state, action) => {
        state.applicantDocuments = []
        state.applicantStatus = RequestStatus.Error
        state.applicantError = action.error.message
      })
  }
})

export const getApplicant = (state: RootState): Applicant => state.applicant.applicant
export const isApplicantReady = (state: RootState): boolean => state.applicant.applicantStatus === RequestStatus.Success
export const getHasApplicant = (state: RootState): boolean => state.applicant.applicant.created_at !== undefined
export const getApplicantStatus = (state: RootState): ApplicantStatus | undefined => state.applicant.applicant.status
export const getIsApplicantVerified = (state: RootState): boolean =>
  state.applicant.applicant.status === ApplicantStatus.Verified
export const getIsApplicantInitialLoading = (state: RootState): boolean => state.applicant.applicantInitialLoading
export const getApplicantDocuments = (state: RootState): ApplicantDocuments[] => state.applicant.applicantDocuments
export const areApplicantDocumentsReady = (state: RootState): boolean =>
  state.applicant.applicantDocumentsStatus === RequestStatus.Success

export default applicantSlice.reducer
